import { isEqual, uniqWith } from "lodash";

import { assertNever } from "../errors.js";
import { HqlNextColumn } from "../explore/types.js";
import {
  AggregateItem,
  AggregationTransform,
  Append,
  CalcExpr,
  Column,
  DataType,
  Detail,
  GroupBy,
  GroupByItem,
  HqlQuery,
  LiteralBool,
  LiteralNumber,
  LiteralString,
  OrderBy,
  SelectExpr,
  SelectItem,
  SelectionTransform,
  Transforms,
  WhereItem,
} from "../semantic-layer/generated/hex_sl_schema.js";
import { typedObjectKeys } from "../utils/typedObjects.js";

import { getDatasetPathForField } from "./hqlNextUtils.js";
import { CalciteType, HqlTruncUnit } from "./types.js";

export function hqlCol(column: string): Column {
  return { name: column };
}

export function hqlBoolean(bool: boolean): LiteralBool {
  return { bool };
}

export function hqlStr(str: string): LiteralString {
  return { str };
}

export function hqlNumber(number: number): LiteralNumber {
  return { number };
}
/**
 * Stores a map of temporary parameter names to their actual argument values. We
 * use this to parameterize all filter arguments to protect against SQL
 * injection. However, hex-sl does not support jinja syntax with literal values,
 * so we replace the literal values with temporaray variable names, and then
 * replace them with their literal values after generating the sql query.
 */
export type HqlNextTemporararyParamMapping = Record<string, string>;
export class HqlNextSpecBuilder {
  private baseDataset: string;

  private transforms: Transforms = [];

  private firstDatasetIsBaseDataset: boolean = true;
  private parameters: Record<string, DataType> = {};
  private temporaryParamMapping: HqlNextTemporararyParamMapping = {};

  constructor(baseDataset: string) {
    this.baseDataset = baseDataset;
  }

  public selection(
    selection: SelectionTransform,
    parameters?: Record<string, DataType>,
    temporaryParamMapping?: HqlNextTemporararyParamMapping,
  ): HqlNextSpecBuilder {
    if (this.transforms.length === 0 && selection.select.length > 0) {
      const dataset = selection.select[0]!.dataset;
      this.firstDatasetIsBaseDataset =
        dataset == null || dataset === this.baseDataset;
    }

    this.transforms.push(selection);
    this.parameters = { ...this.parameters, ...parameters };
    this.temporaryParamMapping = {
      ...this.temporaryParamMapping,
      ...temporaryParamMapping,
    };
    return this;
  }

  public aggregation(
    aggregation: AggregationTransform,
    parameters?: Record<string, DataType>,
    temporaryParamMapping?: HqlNextTemporararyParamMapping,
  ): HqlNextSpecBuilder {
    if (
      this.transforms.length === 0 &&
      ((aggregation.aggregate != null && aggregation.aggregate.length > 0) ||
        (aggregation.group_by != null && aggregation.group_by.length > 0))
    ) {
      const dataset = (aggregation.aggregate?.[0] ?? aggregation.group_by?.[0])!
        .dataset;
      this.firstDatasetIsBaseDataset =
        dataset == null || dataset === this.baseDataset;
    }

    this.transforms.push(aggregation);
    this.parameters = { ...this.parameters, ...parameters };
    this.temporaryParamMapping = {
      ...this.temporaryParamMapping,
      ...temporaryParamMapping,
    };
    return this;
  }

  public addSelections(append: Append): HqlSelectionBuilder {
    return new HqlSelectionBuilder(this, this.baseDataset, append);
  }

  public addAggregations(): HqlAggregationBuilder {
    return new HqlAggregationBuilder(this, this.baseDataset);
  }

  build(): {
    query: HqlQuery;
    temporaryParamMapping: HqlNextTemporararyParamMapping;
  } {
    // If the first dataset occurrence is the base dataset, then we should remove
    // referring to the dataset anywhere as the base dataset name changes in hex-sl
    // internally with each transform. But if the first one isn't the base dataset,
    // then we need to make sure to add the base dataset name to the dataset field
    // if the consumer omitted it.
    const resolveBaseDataset = (o: { dataset?: string | null }): void => {
      if (o.dataset == null && !this.firstDatasetIsBaseDataset) {
        o.dataset = this.baseDataset;
      } else if (
        o.dataset === this.baseDataset &&
        this.firstDatasetIsBaseDataset
      ) {
        o.dataset = undefined;
      }
    };

    this.transforms.forEach((transform) => {
      if ("select" in transform) {
        transform.select.forEach((select) => {
          resolveBaseDataset(select);
        });
        transform.where?.forEach((where) => {
          resolveBaseDataset(where);
        });
      } else {
        transform.aggregate?.forEach((aggregate) => {
          resolveBaseDataset(aggregate);
        });
        transform.group_by?.forEach((groupBy) => {
          resolveBaseDataset(groupBy);
        });
        transform.where?.forEach((where) => {
          resolveBaseDataset(where);
        });
      }
    });

    return {
      query: {
        base: this.baseDataset,
        transforms: this.transforms,
        // query datasets - for now we define everything at the top level dataset
        datasets: [],
        ...(typedObjectKeys(this.parameters).length > 0 && {
          parameters: this.parameters,
        }),
      },
      temporaryParamMapping: this.temporaryParamMapping,
    };
  }
}

abstract class HqlNextGenBuilder {
  protected parent: HqlNextSpecBuilder;
  protected baseDataset: string;
  protected params: Record<string, DataType> = {};
  protected temporaryParamMapping: Record<string, string> = {};
  protected _filters: WhereItem[] = [];
  protected _orderBy: OrderBy | null = null;
  protected _limit: number | null = null;
  protected _offset: number | null = null;

  constructor(parent: HqlNextSpecBuilder, baseDataset: string) {
    this.parent = parent;
    this.baseDataset = baseDataset;
  }

  public filters(columns: (HqlNextColumn & { expr: string[] })[]): this {
    const filters: WhereItem[] = [];

    columns.forEach((column) => {
      const datasetPath = getDatasetPathForField(column);
      column.expr.forEach((expr) => {
        filters.push({
          expr,
          dataset: datasetPath,
        });
      });
    });
    this._filters = filters;
    return this;
  }

  public orderBy(orderBy: OrderBy | null): this {
    this._orderBy = orderBy;
    return this;
  }

  public limit(limit: number | null): this {
    this._limit = limit;
    return this;
  }

  public offset(offset: number | null): this {
    this._offset = offset;
    return this;
  }

  public addParams(
    params: Record<string, DataType>,
    temporaryParamMapping: HqlNextTemporararyParamMapping = {},
  ): void {
    this.params = { ...this.params, ...params };
    this.temporaryParamMapping = {
      ...this.temporaryParamMapping,
      ...temporaryParamMapping,
    };
  }
}

export class HqlSelectionBuilder extends HqlNextGenBuilder {
  private append?: Append;
  private selects: SelectItem[] = [];

  constructor(
    parent: HqlNextSpecBuilder,
    baseDataset: string,
    append?: Append,
  ) {
    super(parent, baseDataset);
    this.append = append;
  }

  public select(select: SelectItem): HqlSelectionBuilder {
    this.selects.push(select);
    return this;
  }

  public buildSelection(): HqlNextSpecBuilder {
    this.parent.selection(
      {
        select: this.selects,
        append: this.append,
        where: this._filters,
        order_by: this._orderBy,
        limit: this._limit,
        offset: this._offset,
      },
      this.params,
      this.temporaryParamMapping,
    );

    return this.parent;
  }
}

export class HqlSelectExprBuilder {
  private expr: CalcExpr;
  private columnType: CalciteType | undefined;

  constructor(
    private columnName: string,
    private dataType: CalciteType | undefined,
  ) {
    this.expr = hqlCol(this.columnName);
    this.columnType = this.dataType;
  }

  public datecast(): HqlSelectExprBuilder {
    const columnType = this.columnType;
    if (columnType == null || columnType === CalciteType.VARCHAR) {
      this.expr = {
        fun: "todatetime",
        args: [this.expr],
      };
      this.columnType = CalciteType.TIMESTAMP;
    } else if (
      columnType === CalciteType.BIGINT ||
      columnType === CalciteType.FLOAT ||
      columnType === CalciteType.DOUBLE ||
      columnType === CalciteType.INTEGER ||
      columnType === CalciteType.DECIMAL
    ) {
      this.expr = {
        fun: "epochmstodatetime",
        args: [this.expr],
      };
      this.columnType = CalciteType.TIMESTAMP;
    } else if (columnType === CalciteType.BOOLEAN) {
      // kind of silly, but internally consistent
      this.expr = {
        fun: "epochmstodatetime",
        args: [
          {
            fun: "if",
            args: [this.expr, hqlNumber(1), hqlNumber(0)],
          },
        ],
      };
      this.columnType = CalciteType.TIMESTAMP;
    } else if (
      columnType === CalciteType.TIMESTAMP ||
      columnType === CalciteType.TIMESTAMPTZ ||
      columnType === CalciteType.DATE
    ) {
      // noop, no need to cast
    } else {
      assertNever(columnType, columnType);
    }

    return this;
  }

  public numbercast(): HqlSelectExprBuilder {
    const columnType = this.columnType;
    if (columnType == null || columnType === CalciteType.VARCHAR) {
      this.expr = {
        fun: "tonumber",
        args: [this.expr],
      };
      this.columnType = CalciteType.FLOAT;
    } else if (
      columnType === CalciteType.DATE ||
      columnType === CalciteType.TIMESTAMP ||
      columnType === CalciteType.TIMESTAMPTZ
    ) {
      this.expr = {
        fun: "datetimetoepochms",
        args: [this.expr],
      };
      this.columnType = CalciteType.INTEGER;
    } else if (columnType === CalciteType.BOOLEAN) {
      this.expr = {
        fun: "if",
        args: [this.expr, hqlNumber(1), hqlNumber(0)],
      };
    } else if (
      columnType === CalciteType.BIGINT ||
      columnType === CalciteType.FLOAT ||
      columnType === CalciteType.DOUBLE ||
      columnType === CalciteType.INTEGER ||
      columnType === CalciteType.DECIMAL
    ) {
      // noop, no need to cast
    } else {
      assertNever(columnType, columnType);
    }

    return this;
  }

  public datetrunc(unit: HqlTruncUnit): HqlSelectExprBuilder {
    const unitToFunTruncUnit = {
      [HqlTruncUnit.year]: "truncyear",
      [HqlTruncUnit.quarter]: "truncquarter",
      [HqlTruncUnit.month]: "truncmonth",
      [HqlTruncUnit.week]: "truncweek",
      [HqlTruncUnit.day]: "truncday",
      [HqlTruncUnit.hour]: "trunchour",
      [HqlTruncUnit.minute]: "truncminute",
      [HqlTruncUnit.second]: "truncsecond",
      [HqlTruncUnit.dayofweek]: "dayofweek",
    } as const;

    this.expr = {
      fun: unitToFunTruncUnit[unit],
      args: [this.expr],
    };
    this.columnType = CalciteType.TIMESTAMP;
    return this;
  }

  public build({ as, dataset }: { as?: string; dataset?: string }): SelectExpr {
    return {
      expr: this.expr,
      alias: as ?? this.columnName,
      dataset: dataset,
    };
  }
}

export class HqlAggregationBuilder extends HqlNextGenBuilder {
  private aggregations: AggregateItem[] = [];
  private groupbys: GroupBy = [];
  private details: Detail = [];

  constructor(parent: HqlNextSpecBuilder, baseDataset: string) {
    super(parent, baseDataset);
  }

  public aggregation(aggregateItem: AggregateItem): HqlAggregationBuilder {
    this.aggregations.push(aggregateItem);

    return this;
  }

  public groupby(groupby: GroupByItem): HqlAggregationBuilder {
    this.groupbys.push(groupby);
    return this;
  }

  public detail(detail: SelectItem): HqlAggregationBuilder {
    this.details.push(detail);
    return this;
  }

  public buildAggregation(args?: {
    skipIfNoAggregations?: boolean;
  }): HqlNextSpecBuilder {
    const builder = this.parent;
    const groupBys = uniqWith(this.groupbys, isEqual);
    if (
      this.aggregations.length > 0 ||
      (groupBys.length > 0 && args?.skipIfNoAggregations !== true)
    ) {
      builder.aggregation(
        {
          group_by: groupBys,
          aggregate: this.aggregations,
          where: this._filters,
          order_by: this._orderBy,
          limit: this._limit,
          offset: this._offset,
          having: undefined, // TODO: implement later
          detail: this.details,
        },
        this.params,
        this.temporaryParamMapping,
      );
    }
    return builder;
  }
}
