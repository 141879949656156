import moment from "moment";
import hashObject from "object-hash";

import {
  computeRelativeDate,
  tryParseRelativeDateOffsetValue,
} from "../../datetime.js";
import { DATE_FORMAT_NO_TIME } from "../../datetimeType.js";
import { displayTableFilterToFilterCellFilter } from "../../display-table/columnFilterUtils.js";
import { ColumnFilter } from "../../display-table/filterTypes.js";
import { FilledDynamicValueTableColumnType } from "../../DynamicValue.js";
import { assertNever } from "../../errors.js";
import { ExploreFieldType } from "../../explore/types.js";
import {
  HqlAggregationBuilder,
  HqlSelectionBuilder,
} from "../../hql/HqlNextSpecBuilder.js";
import { columnTypeToCalciteType } from "../../hql/types.js";
import {
  HexSLTypes,
  columnTypeToHqlNextType,
  isIsoDate,
  typedObjectEntries,
  typedObjectFromEntries,
} from "../../index.js";
import { notEmpty } from "../../notEmpty.js";
import {
  Filter,
  FilterCellBinaryColumnPredicate,
  FilterCellListBinaryColumnPredicate,
  FilterCellUnaryColumnPredicate,
  FilterGroup,
} from "../filterTypes.js";
import {
  DATE_OPERATOR_MAP,
  escapeSingleQuotes,
  filterFullyConfigured,
  getJinjaRegex,
  hasJinjaExpression,
} from "../generateSql.js";

function maybeWrapQuotes({
  arg: rawArg,
  columnType,
}: {
  arg: string;
  columnType: FilledDynamicValueTableColumnType;
}): string {
  if (columnType === "NUMBER") {
    return rawArg;
  } else if (columnType === "BOOLEAN") {
    if (
      rawArg.toLowerCase().trim() === "true" ||
      rawArg.toLowerCase().trim() === "false"
    ) {
      return rawArg.trim();
    }
  }
  return `'${escapeSingleQuotes(rawArg)}'`;
}

/**
 * This casts a datetime column to a date if filtering by a date string so that
 * we have consistent behavior when filtering date + datetime columns.
 *
 * For example, if we have a datetime column and we have a filter `<=
 * 2024-10-01`, without casting the column to a date, any values larger than
 * `2024-10-01 00:00:00` would be excluded. In this case, our desired behavior
 * is to return all rows that are before or on `2024-10-01` (ex. 2024-10-01
 * 00:00:01), so casting the column to a date gives us this behavior.
 *
 *
 */
function maybeDateCastColumn({
  args,
  column,
  columnType,
}: {
  args: string[];
  columnType: FilledDynamicValueTableColumnType;
  column: string;
}): {
  column: string;
  columnType: FilledDynamicValueTableColumnType;
} {
  const isIsoDateArgs = args.some(isIsoDate);
  const isRelativeDateArgs = args.some(
    (arg) => tryParseRelativeDateOffsetValue(arg) != null,
  );

  if (
    // If for whatever reason the column type is unknown we should still try to
    // cast the value to a date if the filter predicate is a date type.
    (isDateTimeColumnType(columnType) || columnType === "UNKNOWN") &&
    (isIsoDateArgs ||
      // Expected behavior for relative date filter is to filter by date:
      // see https://hex-tech-hq.slack.com/archives/C0789UJVB9S/p1728417987819679
      isRelativeDateArgs)
  ) {
    return {
      column: `ToDate(${column})`,
      columnType: "DATE",
    };
  }
  return {
    column,
    columnType,
  };
}

function castDateOrDatetimeArg({
  arg,
  columnType,
}: {
  arg: string;
  columnType: FilledDynamicValueTableColumnType;
}): string {
  if (columnType === "DATE") {
    return `ToDate(${arg})`;
  }
  if (columnType === "DATETIME" || columnType === "DATETIMETZ") {
    return `ToDatetime(${arg})`;
  }
  return arg;
}

function formatArg({
  arg: rawArg,
  columnType,
}: {
  arg: string;
  columnType: FilledDynamicValueTableColumnType;
}): string {
  // handle relative dates
  if (
    columnType === "DATE" ||
    columnType === "DATETIME" ||
    columnType === "DATETIMETZ" ||
    // If the column type is unknown and the predicate is not a date type, then
    // we will just treat this as non date/datetime column type
    columnType === "UNKNOWN"
  ) {
    const m = moment(rawArg);
    if (m.isValid() || rawArg === "") {
      let formattedArg = rawArg;
      if (columnType === "DATE") {
        // if for whatever reason a timestamp arg is specified for a date column
        // type, this ensures that the arg is the right format.
        formattedArg = m.format(DATE_FORMAT_NO_TIME);
      }
      return `"${formattedArg}"`;
    } else {
      const parsedRelativeDate = tryParseRelativeDateOffsetValue(rawArg);
      if (parsedRelativeDate == null) {
        if (columnType === "UNKNOWN") {
          return maybeWrapQuotes({ arg: rawArg, columnType });
        }
        throw Error("Invalid date filter argument");
      }
      const computedDate =
        computeRelativeDate(parsedRelativeDate).format(DATE_FORMAT_NO_TIME);
      return `"${computedDate}"`;
    }
  }
  return maybeWrapQuotes({ arg: rawArg, columnType });
}

function jinjaArg(arg: string | string[]): string {
  return `{{${arg}}}`;
}

function getTemporaryParamName(
  arg: string,
  columnType: FilledDynamicValueTableColumnType,
): string {
  return `param_${hashObject({ arg, columnType })}`;
}

function getTemporaryParamType(
  columnType: FilledDynamicValueTableColumnType,
): FilledDynamicValueTableColumnType {
  // For number/boolean column types, we assume that the filter argument is the
  // correct type and does not need to be casted. However, for date/datetime
  // column types, the argument is just a string and need to be casted after
  // jinja parameterization.
  if (columnType === "NUMBER" || columnType === "BOOLEAN") {
    return columnType;
  }
  return "STRING";
}

function getJinjaReferencedParamName(arg: string): string | undefined {
  if (!hasJinjaExpression(arg)) {
    return;
  }
  const parts = arg.split(getJinjaRegex()).filter((part) => part.length > 0);

  if (parts.length === 0) {
    return;
  }
  if (
    parts.length === 1 &&
    parts[0]!.startsWith("{{") &&
    parts[0]!.endsWith("}}")
  ) {
    // TODO: add additional jinja validation here - the only valid jinja
    // we support is direct param references.
    return parts[0]!.substring(2, parts[0]!.length - 2).trim();
  }

  throw new Error("Unsupported jinja syntax");
}

function isDateTimeColumnType(
  columnType: FilledDynamicValueTableColumnType,
): boolean {
  return columnType === "DATETIME" || columnType === "DATETIMETZ";
}

function getCalcExprFromFilter({
  columnType,
  filter,
  jinjaReferencedParams,
  temporaryParamMapping,
}: {
  filter: Filter;
  columnType: FilledDynamicValueTableColumnType;
  jinjaReferencedParams: string[];
  temporaryParamMapping: Record<
    string,
    { arg: string; type: HexSLTypes.DataType }
  >;
}): string {
  const quotedColumn = `\`${filter.column}\``;
  // TODO(explore-hql-next): add validation for valid column types + filter operations
  if (FilterCellUnaryColumnPredicate.guard(filter.operation)) {
    switch (filter.operation.op) {
      case "IS_TRUE":
        return `${quotedColumn} = True`;
      case "IS_FALSE":
        return `${quotedColumn} = False`;
      case "ALWAYS":
        // what is always ??
        throw new Error("Not implemented");
      case "IS_NULL":
        return `IsNull(${quotedColumn})`;
      case "NOT_NULL":
        return `!IsNull(${quotedColumn})`;
      case "IS_EMPTY":
        // what is is empty? do we still use this?
        throw new Error("Not implemented");
      case "NOT_EMPTY":
        // what is is empty? do we still use this?
        throw new Error("Not implemented");
      default:
        assertNever(filter.operation, filter.operation);
    }
  } else if (FilterCellBinaryColumnPredicate.guard(filter.operation)) {
    const maybeJinjaReferencedParam = getJinjaReferencedParamName(
      filter.operation.arg,
    );

    const {
      column: maybeDateCastedColumn,
      columnType: maybeDateCastedColumnType,
    } = maybeDateCastColumn({
      args: [filter.operation.arg],
      column: quotedColumn,
      columnType,
    });

    const formattedArg =
      maybeJinjaReferencedParam ??
      formatArg({
        arg: filter.operation.arg,
        columnType: maybeDateCastedColumnType,
      });
    const paramRef =
      maybeJinjaReferencedParam ??
      getTemporaryParamName(formattedArg, columnType);

    temporaryParamMapping[paramRef] = {
      arg: formattedArg,
      type: columnTypeToHqlNextType(
        getTemporaryParamType(maybeDateCastedColumnType),
      ),
    };

    jinjaReferencedParams.push(paramRef);

    const jinjaParam = castDateOrDatetimeArg({
      arg: jinjaArg(paramRef),
      columnType: maybeDateCastedColumnType,
    });
    const matchCase = filter.matchCase ?? columnType !== "STRING";

    switch (filter.operation.op) {
      case "DATE_AFTER":
      case "DATE_BEFORE":
      case "DATE_EQUAL_OR_BEFORE":
      case "DATE_EQUAL_OR_AFTER":
      case "DATE_EQUAL":
      case "DATE_NOT_EQUAL": {
        return `${maybeDateCastedColumn} ${DATE_OPERATOR_MAP[filter.operation.op]} ${jinjaParam}`;
      }
      case "GT":
        return `${quotedColumn} > ${jinjaParam}`;
      case "GTE":
        return `${quotedColumn} >= ${jinjaParam}`;
      case "EQ":
      case "NEQ": {
        const operator = filter.operation.op === "EQ" ? "=" : "!=";

        if (!matchCase) {
          return `lower(${quotedColumn}) ${operator} lower(${jinjaParam})`;
        }
        return `${quotedColumn} ${operator} ${jinjaParam}`;
      }
      case "LTE":
        return `${quotedColumn} <= ${jinjaParam}`;
      case "LT":
        return `${quotedColumn} < ${jinjaParam}`;
      case "CONTAINS":
      case "NOT_CONTAINS": {
        const expr = matchCase
          ? `contains(${quotedColumn}, ${jinjaParam})`
          : `contains(lower(${quotedColumn}), lower(${jinjaParam}))`;
        if (filter.operation.op === "CONTAINS") {
          return expr;
        }
        return `!${expr}`;
      }
      default:
        assertNever(filter.operation, filter.operation);
    }
  } else if (FilterCellListBinaryColumnPredicate.guard(filter.operation)) {
    const {
      column: maybeDateCastedColumn,
      columnType: maybeDateCastedColumnType,
    } = maybeDateCastColumn({
      args: filter.operation.arg,
      column: quotedColumn,
      columnType,
    });

    const params = filter.operation.arg.map((arg) => {
      const maybeJinjaReferencedParam = getJinjaReferencedParamName(arg);

      const formattedArg =
        maybeJinjaReferencedParam ??
        formatArg({
          arg,
          columnType: maybeDateCastedColumnType,
        });
      const paramRef =
        maybeJinjaReferencedParam ??
        getTemporaryParamName(formattedArg, columnType);

      temporaryParamMapping[paramRef] = {
        arg: formattedArg,
        type: columnTypeToHqlNextType(
          getTemporaryParamType(maybeDateCastedColumnType),
        ),
      };
      jinjaReferencedParams.push(paramRef);
      const jinjaWrappedArg = jinjaArg(paramRef);

      return castDateOrDatetimeArg({
        arg: jinjaWrappedArg,
        columnType: maybeDateCastedColumnType,
      });
    });

    switch (filter.operation.op) {
      case "IS_ONE_OF":
      case "NOT_ONE_OF": {
        const isOneOf = `IsOneOf(${quotedColumn}, ${params.join(", ")})`;
        if (filter.operation.op === "IS_ONE_OF") {
          return isOneOf;
        } else {
          return `!${isOneOf}`;
        }
      }
      case "DATE_BETWEEN": {
        if (filter.operation.arg.length !== 2) {
          return "";
        }

        return `${maybeDateCastedColumn} >= ${params[0]} AND ${maybeDateCastedColumn} <= ${params[1]} `;
      }
      default:
        assertNever(filter.operation, filter.operation);
    }
  } else {
    assertNever(filter.operation, filter.operation);
  }
}

// TODO(explore-hql-next): maybe validate the generated calc?
function getCalcExprFromFilterGroup(
  group: FilterGroup,
  jinjaReferencedParams: string[],
  temporaryParamMapping: Record<
    string,
    { arg: string; type: HexSLTypes.DataType }
  >,
): string | null {
  if (
    group == null ||
    group.filters.filter(filterFullyConfigured).length === 0
  ) {
    return null;
  }

  const calcExprs = group.filters
    .filter(filterFullyConfigured)
    .map((filterOrGroup) => {
      if (FilterGroup.guard(filterOrGroup)) {
        return getCalcExprFromFilterGroup(
          filterOrGroup,
          jinjaReferencedParams,
          temporaryParamMapping,
        );
      } else if (Filter.guard(filterOrGroup)) {
        const columnType = filterOrGroup.columnType;
        if (columnType == null) {
          return;
        }
        return getCalcExprFromFilter({
          filter: filterOrGroup,
          columnType,
          jinjaReferencedParams,
          temporaryParamMapping,
        });
      }
    })
    .filter(notEmpty);

  if (calcExprs.length === 0) {
    return null;
  }
  if (calcExprs.length === 1) {
    return calcExprs[0]!;
  } else {
    return calcExprs.join(` ${group.operation} `);
  }
}

function getFilterUniqueKey(filter: ColumnFilter): string {
  return filter.column + filter.queryPath?.join("");
}
export function addFiltersToHqlBuilder({
  builder,
  filters,
}: {
  builder: HqlSelectionBuilder | HqlAggregationBuilder;
  filters: ColumnFilter[] | null;
}): void {
  if (filters == null || filters.length === 0) {
    return;
  }

  // each filter can only have a single column, so we can group all filter
  // expressions by column.
  const columnExprMap: Record<string, string[]> = {};

  const params: string[] = [];
  const temporaryParamMapping: Record<
    string,
    { arg: string; type: HexSLTypes.DataType }
  > = {};

  filters.forEach((filter) => {
    const filterOrFilterGroup = displayTableFilterToFilterCellFilter(filter);
    const expr = getCalcExprFromFilterGroup(
      {
        operation: "AND",
        filters: [filterOrFilterGroup],
      },
      params,
      temporaryParamMapping,
    );
    if (expr) {
      columnExprMap[getFilterUniqueKey(filter)] ??= [];
      columnExprMap[getFilterUniqueKey(filter)]!.push(expr);
    }
  });

  // NOTE - historically, we only really support jinja params when filtering on
  // string columns/generally treat the jinja params as strings, so am just
  // specifying these as string types for hex-sl.
  builder.addParams(
    typedObjectFromEntries(
      params.map((param) => [
        param,
        temporaryParamMapping[param]?.type ?? ("string" as const),
      ]),
    ),
    typedObjectFromEntries(
      typedObjectEntries(temporaryParamMapping).map(([param, value]) => [
        param,
        value.arg,
      ]),
    ),
  );

  builder.filters(
    filters.map((f) => ({
      expr: columnExprMap[getFilterUniqueKey(f)]!,
      value: f.column,
      dataType: f.columnType
        ? columnTypeToCalciteType(f.columnType)
        : "VARCHAR",
      queryPath: f.queryPath,
      fieldType: f.fieldType ?? ExploreFieldType.COLUMN,
    })),
  );
}
