import { isEqual } from "lodash";

import { generateColumnIdForField } from "../explore/exploreFieldUtils.js";
import { ExploreDetailField, ExploreSpec } from "../explore/types.js";
import { HexSLTypes } from "../index.js";

import {
  HqlAggregationBuilder,
  HqlSelectionBuilder,
} from "./HqlNextSpecBuilder.js";
import { getDatasetPathForField } from "./hqlNextUtils.js";

export function addDetailFieldsToHqlBuilder({
  builder,
  fields,
  spec,
}: {
  builder: HqlSelectionBuilder | HqlAggregationBuilder;
  spec: ExploreSpec;
  fields: ExploreDetailField[];
}): void {
  function addField({
    selectItem,
  }: {
    selectItem: HexSLTypes.SelectItem;
    dimension?: HexSLTypes.Dimension;
  }): void {
    if (builder instanceof HqlAggregationBuilder) {
      builder.detail(selectItem);
    } else {
      builder.select(selectItem);
    }
  }
  if (fields.length === 0) {
    return;
  }

  fields.forEach((field) => {
    // if a field is already selected in the group by of the transform, we
    // don't need to add it again as a detail field
    if (
      spec.fields.find(
        (f) =>
          f.value === field.value &&
          f.truncUnit == null &&
          f.aggregation == null &&
          isEqual(f.queryPath, field.queryPath),
      )
    ) {
      return;
    }

    let dimension;

    if (field.queryPath.length === 0) {
      addField({
        selectItem: {
          dimension: field.value,
          dataset: getDatasetPathForField(field),
        },
        dimension,
      });
    } else {
      const alias = generateColumnIdForField(field);
      addField({
        selectItem: {
          expr: { name: field.value },
          alias,
          dataset: getDatasetPathForField(field),
        },
        dimension,
      });
    }
  });
}
