import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import { CellId } from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps.js";
import { uuid } from "../../uuid.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { CREATE_CELL } from "./createCellOperation";
import { RESTORE_CELL_TYPE } from "./restoreCellOperation";

export type DeleteOriginMetadata =
  | "CELL_ACTION_MENU"
  | "KEYBOARD"
  | "PARENT"
  | "COMMAND_PALETTE"
  | "MULTISELECT_BAR";

export const DELETE_CELL_TYPE = "DELETE_CELL" as const;
export const DELETE_CELL = createHexVersionAtomicOperationDefinition({
  type: DELETE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
    idArg: "cellId",
    idType: "Cell",
  },
  logSafe: ["cellId"],
  conflictId: (op) =>
    `${DELETE_CELL_TYPE}-${RESTORE_CELL_TYPE}-${op.payload.cellId}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  excludeFromHistory: (op) => op.payload.excludeFromHistory,
  create: ({
    cellId,
    deletionEventId = uuid(),
    excludeFromHistory = false,
    initiatedByParentBlockCell = false,
    origin,
    skipToaster = false,
  }: {
    cellId: CellId;
    deletionEventId?: string;
    initiatedByParentBlockCell?: boolean;
    excludeFromHistory?: boolean;
    skipToaster?: boolean;
    origin?: DeleteOriginMetadata;
  }) => ({
    type: DELETE_CELL_TYPE,
    payload: {
      cellId,
      deletionEventId,
      initiatedByParentBlockCell,
      excludeFromHistory,
      skipToaster,
      origin,
    },
  }),
});

export type DELETE_CELL = ExtractAtomicOperationFromDefinition<
  typeof DELETE_CELL
>;
