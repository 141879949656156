import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import { CellId } from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { CREATE_CELL } from "./createCellOperation";

export type MoveOriginMetadata =
  | "ADD_CELL_BAR"
  | "MULTISELECT_BAR"
  | "CELL_ACTION_MENU"
  | "DRAG_AND_DROP"
  | "EJECT";

const MOVE_CELL_TYPE = "MOVE_CELL" as const;
export const MOVE_CELL = createHexVersionAtomicOperationDefinition({
  type: MOVE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
    idArg: "cellId",
    idType: "Cell",
  },
  logSafe: [
    "cellId",
    "insertAfter",
    "insertBefore",
    "insertAt",
    "parentCellId",
  ],
  conflictId: (op) => `${MOVE_CELL_TYPE}-${op.payload.cellId}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  excludeFromHistory: (op) => op.payload.excludeFromHistory ?? false,
  create: (args: {
    cellId: CellId;
    insertAfter: string | undefined;
    insertBefore: string | undefined;
    parentCellId: CellId | undefined;
    insertAt: string;
    excludeFromHistory?: boolean;
    origin?: MoveOriginMetadata;
  }) => ({
    type: MOVE_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type MOVE_CELL = ExtractAtomicOperationFromDefinition<typeof MOVE_CELL>;
