import { ExploreCalcsDefinition, HexSLTypes } from "../index.js";
import { typedObjectEntries } from "../utils/typedObjects.js";

import {
  HqlAggregationBuilder,
  HqlSelectionBuilder,
} from "./HqlNextSpecBuilder.js";
import { columnTypeToHqlNextType } from "./hqlNextUtils.js";

export function addCalcReferencedParamsToHqlBuilder({
  builder,
  calcs,
}: {
  builder: HqlSelectionBuilder | HqlAggregationBuilder;
  calcs: ExploreCalcsDefinition;
}): void {
  // add any referenced params
  if (calcs.referencedParamTypes) {
    const params = typedObjectEntries(calcs.referencedParamTypes).reduce(
      (acc, [name, type]) => {
        acc[name] = columnTypeToHqlNextType(type);
        return acc;
      },
      {} as Record<string, HexSLTypes.DataType>,
    );
    builder.addParams(params);
  }
}
